import React from 'react';
import instagram from '../../assets/img/instagram-logo.webp';
import twitter from  '../../assets/img/twitter-logo.webp';
import facebook from  '../../assets/img/facebook-logo.webp';
import mail from  '../../assets/img/mail.webp';
import call from  '../../assets/img/call.webp';
import man from  '../../assets/img/nativo.webp';
import travel_holidays from '../../assets/img/travel.webp';
import study_australia from '../../assets/img/study.webp';

const index = () => {
  return (
    <div className='header-bg'>
        <div id="header">
            <div id="particles"></div>
            <div className="social-media-links">
                <a href="https://web.facebook.com/incadventure.srl" target='blank'><img src={facebook} className="social-media" alt="facebook-logo"/></a>
                <a href="https://www.instagram.com/incadventuretour" target='blank'><img src={instagram} className="social-media" alt="instagram-logo"/></a>
                <a href="https://twitter.com/INCADVENTURESRL" target='blank'><img src={twitter} className="social-media" alt="twitter-logo"/></a>
            </div>
            <div className="header-content">
                <div className="header-content-box">
                    <div className="firstline"><span className="color">INCADVENTURE </span>TOURS</div>
                    <div className="secondline">Why Choose?
                        <span className="txt-rotate color" data-period="1200"data-rotate='[ " Experience", " Personalized Service", " Network", " Support" ]'></span>
                        <span className="slash">|</span>
                    </div>
                    <div className="contact">
                        <a href="Mailto:incadventure@gmail.com" target='blank'><img src={mail} alt="email-pic" className="contactpic"/></a>
                        <a href="https://wa.link/bofd56" target='blank'><img src={call} alt="phone-pic" className="contactpic"/></a>
                    </div>    
                </div>
                <div className="header-content-box">
                    <div className="grid">
                        
                        <div className="grid-item">
                        <div className="item_wrapper d-flex">
                            <div className="item_content d-flex">      
                            <div className="btn_learnmore w-100">
                                <a href="https://incadventuretours.com.au/travel_holidays" >
                                    <img src={travel_holidays}/>
                                    TRAVEL AND HOLIDAYS
                                </a>
                            </div>              
                            </div>
                        </div>   
                        </div>
                        <div className="grid-item">
                        <div className="item_wrapper d-flex">
                            <div className="item_content d-flex">            
                            <div className="btn_learnmore w-100">                                
                                <a href="https://incadventuretours.com.au/" >
                                    <img src={study_australia}/>
                                    STUDY IN AUSTRALIA
                                </a>
                            </div>              
                            </div>
                        </div>   
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="header-image">
                {/*<img src={man} alt="logo"/>*/}
            </div>
            
        </div>
    </div>
  )
}

export default index