import React from 'react';
import isotipo from '../../assets/img/incadventure-logo-white.webp';

const navigation_content = () => {
  return (
    <div id="navigation-content">
            <div className="logo">
                <img src={isotipo} alt="logo"/>
            </div>
            <div className="navigation-close">
                <span className="close-first"></span>
                <span className="close-second"></span>
            </div>
            <div className="navigation-links">
                <a href="#" data-text="TRAVEL AND HOLIDAYS" id="home-link" >TRAVEL AND HOLIDAYS</a>
                <a href="#" data-text="STUDY IN AUSTRALIA" id="about-link" >STUDY IN AUSTRALIA</a>
            </div>
        </div>
  )
}

export default navigation_content