import React from 'react';
import isotipo from '../../assets/img/incadventure-logo-white.webp';

const navigationbar = () => {
  return (
    <div id="navigation-bar">
            <img src={isotipo} alt="logo"/>
            <div className="menubar">
                <span className="first-span"></span>
                <span className="second-span"></span>
                <span className="third-span"></span>
            </div>
    </div>
  )
}

export default navigationbar