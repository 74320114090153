import './App.css';
import './Theme.css';
import './assets/css/boostrap.min.css';
import './assets/css/style.css';

import Menu1 from './Componentes/layout/navigation_content';
import Menu2 from './Componentes/layout/navigationbar';
import Home from './Componentes/pages/index';

function App() {
  return (
    <div className="App" id='all'>
        <div className="cursor"></div>

        {/*<div id="loader">
            <span className="color">Arlo</span>Brown 
        </div>

        <div id="breaker">
        </div>
        <div id="breaker-two">
        </div>*/}
      <Menu1/>
      <Menu2/>
      <Home/>
      
    </div>
  );
}

export default App;
